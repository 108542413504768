import { $ } from '@gebruederheitz/wp-frontend-utils';

export class FilteredElement {
    constructor(element, eventProxy, options) {
        this.element = element;
        this.eventProxy = eventProxy;
        this.options = options;

        this.initialized = false;
        this.isVisible = true;

        this.onFilterChanged = this.onFilterChanged.bind(this);

        const filterDummy = $(element)(this.options.selector.filterMarker);

        if (!filterDummy) return;

        this.filterToApply = filterDummy.dataset.ghTopicFilter || null;
        this.filterTitle = filterDummy.dataset.ghTopicTitle || '';

        this.listen();
        this.initialized = true;
    }

    get debug() {
        if (this.options && this.options.debug) {
            return console;
        } else {
            return {
                log() {},
                warn() {},
                error() {},
            };
        }
    }

    getTopic() {
        if (this.filterToApply) {
            return {
                slug: this.filterToApply,
                title: this.filterTitle,
            };
        } else {
            return null;
        }
    }

    hasInitialized() {
        return this.initialized;
    }

    hide() {
        this.element.classList.add(this.options.classNames.hidden);
        this.isVisible = false;
    }

    listen() {
        this.eventProxy.on('filter.change', this.onFilterChanged);
    }

    onFilterChanged(newFilter) {
        this.debug.log('Filter change event received', {
            newFilter,
            element: this,
        });
        if (
            !this.isVisible &&
            (newFilter === null || newFilter === this.filterToApply)
        ) {
            this.show();
            return;
        }
        if (
            this.isVisible &&
            newFilter !== null &&
            newFilter !== this.filterToApply
        ) {
            this.hide();
        }
    }

    show() {
        this.element.classList.remove(this.options.classNames.hidden);
        this.isVisible = true;
    }
}
