import { $, $$, createDomElement } from '@gebruederheitz/wp-frontend-utils';
import _merge from 'lodash-es/merge';
import _uniqWith from 'lodash-es/uniqWith';
import _isEqual from 'lodash-es/isEqual';
import { EventEmitter2 } from 'eventemitter2';

import { FilteredElement } from './filtered-element.js';

const DEFAULT_OPTIONS = {
    selector: {
        filtered: '.c-katalog-card-component',
        filterContainer: '#gh-topic-filter',
        filterMarker: '[data-gh-topic-filter]',
    },
    classNames: {
        hidden: 'hidden',
    },
    withHashNavigation: true,
    debug: false,
};

export class TopicFilter {
    constructor(userOptions) {
        this.topics = [];
        this.filteredElements = [];
        this.options = {};
        this.filterInput = null;
        this.currentFilter = '';

        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.onHashChange = this.onHashChange.bind(this);

        this.eventProxy = new EventEmitter2();
        this.eventProxy.setMaxListeners(200);

        this.parseOptions(userOptions);

        this.getFilteredElements();

        if (!this.filteredElements.length) return;

        this.getTopics();

        this.createFilterInput();

        this.listen();
    }

    createFilterInput() {
        const container = $()(this.options.selector.filterContainer);

        const select = createDomElement({
            type: 'SELECT',
            attributes: {
                name: 'gh-topic-filter',
            },
            classNames: ['w-select', 'gh-topic-filter__select'],
        });

        createDomElement({
            type: 'OPTION',
            attributes: {
                value: '',
            },
            innerText: 'Alle Themen',
            parent: select,
        });

        this.topics.forEach((topic) => {
            createDomElement({
                type: 'OPTION',
                attributes: {
                    value: topic.slug,
                },
                innerText: topic.title,
                parent: select,
            });
        });

        container.appendChild(select);

        this.filterInput = select;
    }

    get debug() {
        if (this.options && this.options.debug) {
            return console;
        } else {
            return {
                log() {},
                warn() {},
                error() {},
            };
        }
    }

    getTopics() {
        const topics = [];
        this.filteredElements.forEach((el) => {
            topics.push(el.getTopic());
        });
        this.topics = _uniqWith(topics, _isEqual).filter((e) => e !== null);
    }

    getFilteredElements() {
        const filteredDomElements = $$()(this.options.selector.filtered);

        this.debug.log('filtered elements', filteredDomElements);

        if (!filteredDomElements.length) return;

        filteredDomElements.forEach((element) => {
            const filtered = new FilteredElement(
                element,
                this.eventProxy,
                this.options
            );
            this.debug.log(
                'created filtered element',
                filtered,
                filtered.hasInitialized()
            );
            if (filtered && filtered.hasInitialized()) {
                this.filteredElements.push(filtered);
            }
        });
    }

    listen() {
        this.filterInput.addEventListener('change', this.onFilterChanged);

        if (this.options.withHashNavigation) {
            window.addEventListener('hashchange', this.onHashChange);
            this.onHashChange();
        }
    }

    onFilterChanged() {
        this.currentFilter = this.filterInput.value || null;
        this.debug.log('Filter changed', {
            filterValue: this.currentFilter,
            elements: this.filteredElements,
        });
        this.eventProxy.emit('filter.change', this.currentFilter);

        if (this.options.withHashNavigation) {
            let newHash = '';

            if (this.currentFilter) {
                newHash = '#' + this.currentFilter;
            }

            window.history.replaceState(
                '',
                document.title,
                window.location.pathname + newHash + window.location.search
            );
        }
    }

    onHashChange() {
        const newHash = window.location.hash;

        this.debug.log('Hash change', { newHash, _this: this });

        if (!newHash || newHash === '#') return;
        if (!this.topics.find((e) => e.slug === newHash.substr(1))) return;
        if (newHash.substr(1) === this.currentFilter) return;

        this.currentFilter = newHash.substr(1);
        this.filterInput.value = this.currentFilter;
        this.eventProxy.emit('filter.change', this.currentFilter);
    }

    parseOptions(userOptions) {
        this.options = _merge(DEFAULT_OPTIONS, userOptions);
    }
}
